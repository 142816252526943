import styled from 'styled-components';
import Img from 'gatsby-image';
import IndexLayout from '../layouts';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { AppTitle, NavBar, TextCycler } from '../components/ui';
import { Link, graphql } from 'gatsby';
import { BottomNavSegment, Footer, OurShopifyInsightsSegment } from '../components/PageSegments';
import favicon from '../content/img/icon/favicon.ico';
import { Research4Segment } from '../components/PageSegments/research4/Research4main';

const ResearchPage3 = () => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Base10 Trend: Mental Health</title>
        <link rel="icon" href={favicon} />
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <meta charSet="UTF-8" />
        <link rel="canonical" href="https://base10.vc/mental-health/" />
        <meta name="og:url" content="https://base10.vc/mental-health/" />
        <meta name="og:title" content="Base10 Trend: Mental Health" />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/base10/image/upload/v1611953635/general-research/mental_health_preview_2_rhrqat.png"
        />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta name="og:description" content="Base10 Trend: Mental Health" />
        <meta name="description" content="Base10 Trend: Mental Health" />
        <meta name="twitter:title" content="Base10 Trend: Mental Health" />
        <meta name="twitter:description" content="Base10 Trend: Mental Health" />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/base10/image/upload/v1611953635/general-research/mental_health_preview_2_rhrqat.png"
        />
        <meta name="twitter:image:alt" content="Base10 Trend: Mental Health" />
        <meta name="twitter:site" content="@Base10Partners" />
        <meta name="twitter:creator" content="@Base10Partners" />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Main>
        <NavBar invert />
        <Research4Segment />
        <Footer />
        <BottomNavSegment />
      </Main>
    </IndexLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* height: 2000px; */
  /* background: grey; */
`;

export default ResearchPage3;
