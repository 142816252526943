import React, { FunctionComponent, useState, useEffect, useMemo, useReducer } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { mobileSize, laptopSize, desktopSize } from '../../../util/variables';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { AnimatedLinkAnchor, AppText, AppTitle, AnimatedSpan } from '../../ui';
import ReactTooltip from 'react-tooltip';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { gql, useMutation } from '@apollo/client';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Axios from 'axios';
import { GraphsComponent } from '../shopify/GraphsComponent';
import { useImageList } from '../../../content/img/research/imagesList';
import { numberToCurrencyMorB } from '../../../util/numbers';

const POST_MUTATION = gql`
  mutation creatingMarketingEmail($email: String!, $source: String!) {
    createOneMarketingEmail(data: { email: $email, source: $source }) {
      id
      email
    }
  }
`;

interface Props {
  data?: any;
}

const SHEETS_URL = 'https://sheet.best/api/sheets/33901225-d6de-488d-8a0c-b9bd18ee166c';

const listColors = ['#45aeeb', '#3586b5', '#8bd5ff'];

const Research4Segment: FunctionComponent<Props> = () => {
  const [showMore, setShowMore] = useState([]);
  const [guestEmail, setGuestEmail] = useState('');
  const [keyInsights, setKeyInsights] = useState([]);
  const [companiesOver500Data, setCompaniesOver500Data] = useState([]);
  const [fundingByYearData, setFundingByYearData] = useState([]);
  const [cumulativeFundingData, setCumulativeFundingData] = useState([]);
  const [totalCompaniesIdentifiedData, setTotalCompaniesIdentifiedData] = useState([]);
  const [mostActiveInvestorsData, setMostActiveInvestorsData] = useState([]);
  const [investorsInTopCompaniesData, setInvestorsInTopCompaniesData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const [linkedInData, setLinkedInData] = useState([]);
  const [tableTooltipsData, setTableTooltipsData] = useState([]);
  const [mapData, setMapData] = useState({
    latam: [],
    uscan: [],
    emea: [],
    apac: [],
  });
  const [filters, setFilters] = useState({
    segment: '',
    location: '',
    stage: '',
    amount_raised: '',
    company_size: '',
    investor: '',
  });

  const [addEmailData, { emailData }] = useMutation(POST_MUTATION);

  /**
   * Fetch data from Google Sheets
   */
  useEffect(() => {
    const fetchKeyInsightsData = async () => {
      const keyInsightPoints = await Axios.get(`${SHEETS_URL}/tabs/key_insights`);
      setKeyInsights(keyInsightPoints.data);
    };

    const fetchCompaniesOver500Data = async () => {
      const companiesOver500mResponse = await Axios.get(`${SHEETS_URL}/tabs/companies_over_500m`);
      setCompaniesOver500Data(
        companiesOver500mResponse.data.map((item: any) => ({
          year: item.year,
          number: item.num_companies,
        })),
      );
    };

    const fetchFundingByYearData = async () => {
      const fundingByYearResponse = await Axios.get(`${SHEETS_URL}/tabs/funding_by_year`);
      setFundingByYearData(
        fundingByYearResponse.data.map((item: any) => ({
          year: item.year,
          amount: Number(item.amount_usdm),
        })),
      );
    };

    const fetchCumulativeFundingData = async () => {
      const cumulativeFundingResponse = await Axios.get(`${SHEETS_URL}/tabs/cumulative_funding`);
      setCumulativeFundingData(
        cumulativeFundingResponse.data.map((item: any) => ({
          year: item.year,
          amount: Number(item.amount_usdm),
        })),
      );
    };

    const fetchTotalCompaniesIdentifiedData = async () => {
      const totalCompaniesIdentifiedResponse = await Axios.get(
        `${SHEETS_URL}/tabs/total_companies_identified`,
      );
      setTotalCompaniesIdentifiedData(
        totalCompaniesIdentifiedResponse.data.map((item: any) => ({
          title: item.stage,
          number: item.num_companies,
        })),
      );
    };

    const fetchMostActiveInvestorsData = async () => {
      const totalCompaniesIdentifiedResponse = await Axios.get(
        `${SHEETS_URL}/tabs/most_active_investors`,
      );
      setMostActiveInvestorsData(totalCompaniesIdentifiedResponse.data);
    };

    const fetchInvestorsInTopCompaniesData = async () => {
      const investorsInTopCompaniesResponse = await Axios.get(
        `${SHEETS_URL}/tabs/investors_in_top_companies`,
      );
      setInvestorsInTopCompaniesData(investorsInTopCompaniesResponse.data);
    };

    const fetchMainTableData = async () => {
      const mainTableDataResponse = await Axios.get(`${SHEETS_URL}/tabs/universe_table`);
      setMainTableData(mainTableDataResponse.data);
    };

    const fetchLinkedInData = async () => {
      const linkedInResponse = await Axios.get(`${SHEETS_URL}/tabs/founder_linkedins`);
      setLinkedInData(linkedInResponse.data);
    };

    const fetchMapData = async () => {
      const mapDataResponse = await Axios.get(`${SHEETS_URL}/tabs/map`);
      const latam = mapDataResponse.data.map(item => item.latam).filter(item => Boolean(item));
      const uscan = mapDataResponse.data.map(item => item.uscan).filter(item => Boolean(item));
      const emea = mapDataResponse.data.map(item => item.emea).filter(item => Boolean(item));
      const apac = mapDataResponse.data.map(item => item.apac).filter(item => Boolean(item));
      setMapData({ latam, uscan, emea, apac });
    };

    const fetchTooltipsData = async () => {
      const tooltipsResponse = await Axios.get(`${SHEETS_URL}/tabs/segment_detail`);
      setTableTooltipsData(tooltipsResponse.data);
    };

    fetchKeyInsightsData();
    fetchCompaniesOver500Data();
    fetchFundingByYearData();
    fetchCumulativeFundingData();
    fetchTotalCompaniesIdentifiedData();
    fetchMostActiveInvestorsData();
    fetchInvestorsInTopCompaniesData();
    fetchMainTableData();
    fetchLinkedInData();
    fetchMapData();
    fetchTooltipsData();
  }, []);

  console.log('MAP: ', mapData);

  // Filters
  const uniqueSegments = useMemo(
    () =>
      mainTableData
        .map(c => c.segment)
        .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
        .sort(),
    [mainTableData],
  );

  const uniqueLocations = useMemo(
    () =>
      mainTableData
        .reduce((tot, x) => [...tot, x.hq_location.split(', ').pop()], [])
        .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
        .filter(i => i !== '')
        .sort(),
    [mainTableData],
  );

  const uniqueStages = useMemo(
    () =>
      mainTableData
        .map(c => c.stage)
        .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
        .sort(),
    [mainTableData],
  );

  const uniquePeople = useMemo(() => {
    if (!mainTableData.length) {
      return [];
    }

    const stepVal = Math.round(
      Math.round(
        Math.max(
          ...mainTableData.filter(item => Boolean(item.employees)).map(c => Number(c.employees)),
        ) / 50,
      ) * 10,
    );

    const returnVal = [
      { label: 'Employees', value: '' },
      { label: `< ${(stepVal / 3).toLocaleString()}`, value: `${0}-${stepVal / 3}` },
      {
        label: `${(stepVal / 3).toLocaleString()} - ${(stepVal * 1.5).toLocaleString()}`,
        value: `${stepVal / 3}-${stepVal * 1.5}`,
      },
      {
        label: `${(stepVal * 1.5).toLocaleString()} - ${(stepVal * 3).toLocaleString()}`,
        value: `${stepVal * 1.5}-${stepVal * 3}`,
      },
      { label: `> ${(stepVal * 3).toLocaleString()}`, value: `${stepVal * 3}-${stepVal * 10}` },
    ];
    console.log('returnVal', returnVal);

    return returnVal;
  }, [mainTableData]);

  const uniqueAmounts = useMemo(() => {
    if (!mainTableData.length) {
      return [];
    }

    const stepVal =
      Math.round(
        Math.max(
          ...mainTableData.filter(item => Boolean(item.amt_raised)).map(c => Number(c.amt_raised)),
        ) / 5000,
      ) * 1000;

    const returnVal = [
      { label: 'Any amount raised', value: '' },
      { label: `< ${numberToCurrencyMorB(stepVal / 3)}`, value: `${0}-${stepVal / 3}` },
      {
        label: `${numberToCurrencyMorB(stepVal / 3)} - ${numberToCurrencyMorB(stepVal * 1.5)}`,
        value: `${stepVal / 3}-${stepVal * 1.5}`,
      },
      {
        label: `${numberToCurrencyMorB(stepVal * 1.5)} - ${numberToCurrencyMorB(stepVal * 3)}`,
        value: `${stepVal * 1.5}-${stepVal * 3}`,
      },
      { label: `> ${numberToCurrencyMorB(stepVal * 3)}`, value: `${stepVal * 3}-${stepVal * 10}` },
    ];
    return returnVal;
  }, [mainTableData]);

  const uniqueInvestors = useMemo(() => {
    if (!mainTableData.length) {
      return [];
    }

    return mainTableData
      .map(item => item.key_investors)
      .map(item => item.split(', '))
      .reduce((a, b) => a.concat(b))
      .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
      .filter(item => Boolean(item))
      .sort();
  }, [mainTableData]);

  const MoreButton: JSX.Element = (props: any) => {
    return (
      <MoreButtonStyle
        onClick={() => {
          trackCustomEvent({
            action: 'Click',
            category: `Table: ${props.table}`,
            label: `${showMore.includes(props.id) ? 'less' : 'more'} click on ${props.column}`,
          });
          showMore.includes(props.id)
            ? setShowMore(showMore.filter(i => i !== props.id))
            : setShowMore(showMore.concat(props.id));
        }}
      >
        {showMore.includes(props.id) ? 'less' : 'more'}
      </MoreButtonStyle>
    );
  };

  const filterTableData = () => {
    return mainTableData
      .slice()
      .sort((a: any, b: any) =>
        a.segment.toLowerCase() > b.segment.toLowerCase()
          ? 1
          : a.segment.toLowerCase() < b.segment.toLowerCase()
          ? -1
          : 0,
      )
      .filter(item => Boolean(!filters.segment) || item.segment === filters.segment)
      .filter(
        item =>
          Boolean(!filters.location) || item.hq_location.split(', ').pop() === filters.location,
      )
      .filter(item => Boolean(!filters.stage) || item.stage === filters.stage)
      .filter(
        item =>
          Boolean(!filters.investor) || item.key_investors.split(', ').includes(filters.investor),
      )
      .filter(
        item =>
          Boolean(!filters.company_size) ||
          (Number(item.employees) >= Number(filters.company_size.split('-')[0]) &&
            Number(item.employees) < Number(filters.company_size.split('-')[1])),
      )
      .filter(
        item =>
          Boolean(!filters.amount_raised) ||
          (Number(item.amt_raised) >= Number(filters.amount_raised.split('-')[0]) &&
            Number(item.amt_raised) < Number(filters.amount_raised.split('-')[1])),
      );
  };

  const handleEmailSubmit = async (e: any): Promise<any> => {
    e.preventDefault();
    trackCustomEvent({ action: 'Click', category: 'Email', label: 'Email button click' });
    if (guestEmail.includes('@') && guestEmail.trim().length > 6 && guestEmail.includes('.')) {
      await addEmailData({ variables: { email: guestEmail, source: 'mental-health' } });
      setGuestEmail('Email address submitted!');
      trackCustomEvent({
        action: 'Save',
        category: 'Email',
        label: 'Email submissions successful',
      });
    } else {
      setGuestEmail('Invalid email address!');
      trackCustomEvent({
        action: 'Save',
        category: 'Email',
        label: 'Email submission validation failure',
      });
    }
  };

  const section_titles = useMemo(
    () =>
      mainTableData
        .map(st => st.segment)
        .filter((value, index, self) => self.indexOf(value) === index),
    [mainTableData],
  );

  const filteredTableData = useMemo(() => filterTableData(), [filterTableData, filters]);

  const findTooltipForSegment = (segment: string) => {
    const tooltip = tableTooltipsData
      .map((item: any) => item)
      .filter((item: any) => item.segment === segment);
    if (!!tooltip) return tooltip[0]?.detail;
    return false;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [mainTableData]);

  return (
    <ShopifyInsightsContainer>
      <ReactTooltip
        multiline
        place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: 'center',
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor="rgba(0, 0, 0, 0.9)"
      />
      <EmailForm onSubmit={handleEmailSubmit}>
        <EmailSubmitButton type="submit">Sign up</EmailSubmitButton>
        <EmailTextInput
          type="email"
          name="email"
          value={guestEmail}
          placeholder="Enter email here..."
          data-tip="If you’re interested in updates to our research or new research we publish, please enter your email address here"
          data-place="bottom"
          onChange={e => setGuestEmail(e.target.value)}
          onFocus={() =>
            trackCustomEvent({ action: 'Click', category: 'Email', label: 'Email input click' })
          }
        />
      </EmailForm>
      {/* <NavSelectLabel>Select a research page to view</NavSelectLabel>
      <NavSelect onChange={async (e: any) => navigate(e.target.value)}>
        <option value="/mental-health/">Base10 Trend: Mental Health</option>
        <option value="/restaurant-tech/">Base10 Trend: Restaurant Technology</option>
        <option value="/research-advancement-initiative/">
          Advancement Fund Initiative - Portfolio & Universe Construction
        </option>
        <option value="/ecommerce-shopify-saas/">
          Base10 Trend: Commerce Enablement Tools for the Retail Industry
        </option>
      </NavSelect> */}
      <SummaryTitle>
        <SummaryTitleText>Summary of</SummaryTitleText>
        &nbsp;
        <SummaryTitleText bold>Base10 Trend: Mental Health</SummaryTitleText>
      </SummaryTitle>
      <KeyInsightsListTitle>Base10 key insights</KeyInsightsListTitle>
      <KeyInsightsListItems>
        <ol>
          {keyInsights.map((item: any, index: number) => (
            <li key={item.insight_bold} data-ordinal={index}>
              <b style={{ fontSize: '16px' }}>{item.insight_bold}</b> {item.insight_content}
            </li>
          ))}
        </ol>
      </KeyInsightsListItems>

      <GraphsComponent
        numberOfUnicorns={companiesOver500Data}
        amountOfFunding={fundingByYearData}
        cumulativeRaised={cumulativeFundingData}
        circleNumbers={totalCompaniesIdentifiedData}
        firstGraphLabel=">$500M in Value or Exited"
        researchPageNumber={3}
        mapCompanies={mapData}
      />

      <FiltersList>
        <FilterHeadingLabel>{filteredTableData.length} Companies</FilterHeadingLabel>
        <FilterByLabel>Filter by</FilterByLabel>
        <FitlerSelect
          name="segments"
          id="segments"
          onChange={e => {
            trackCustomEvent({
              action: 'Click',
              category: 'Filter',
              label: `Segment filter change to ${e.target.value}`,
            });
            setFilters({ ...filters, segment: e.target.value });
          }}
        >
          <option value="">All segments</option>
          {uniqueSegments.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>

        <FitlerSelect
          id="locations"
          onChange={e => {
            setFilters({ ...filters, location: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: 'Filter',
              label: `Location filter change to ${e.target.value}`,
            });
          }}
        >
          <option value="">All locations</option>
          {uniqueLocations.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>

        <FitlerSelect
          id="stages"
          onChange={e => {
            setFilters({ ...filters, stage: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: 'Filter',
              label: `Stage filter change to ${e.target.value}`,
            });
          }}
        >
          <option value="">All stages</option>
          {uniqueStages.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>

        <FitlerSelect
          id="amounts"
          onChange={e => {
            setFilters({ ...filters, amount_raised: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: 'Filter',
              label: `Amounts filter change to ${e.target.value}`,
            });
          }}
        >
          {uniqueAmounts.map(item => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </FitlerSelect>

        <FitlerSelect
          id="people"
          onChange={e => {
            setFilters({ ...filters, company_size: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: 'Filter',
              label: `People filter change to ${e.target.value}`,
            });
          }}
        >
          {uniquePeople.map(item => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </FitlerSelect>

        <FitlerSelect
          id="investors"
          onChange={e => {
            setFilters({ ...filters, investor: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: 'Filter',
              label: `Investor filter change to ${e.target.value}`,
            });
          }}
        >
          <option value="">All investors</option>
          {uniqueInvestors.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>
      </FiltersList>

      <ResearchInvestorsFullDiv>
        <ResearchCompaniesDiv>
          <table>
            <thead>
              <tr>
                <th style={{ position: 'sticky', left: 0, zIndex: 4 }}>Company</th>
                <th id="secondColHead">Segment</th>
                <th>Founders</th>
                <th>Stage</th>
                <th>Founded</th>
                <th>HQ. Location</th>
                <th>Amt. Raised</th>
                <th>Employees</th>
                <th>Key Investors</th>
                <th>Base10 Description</th>
              </tr>
            </thead>
            {filteredTableData.length > 0 ? (
              section_titles.map(item => (
                <tbody key={item}>
                  {filteredTableData.filter(c => c.segment === item).length > 0 && (
                    <tr key={`${item}-${item}`}>
                      <td colSpan={3} style={{ position: 'sticky', left: '0' }}>
                        <TableSubTitle>
                          {item}
                          {findTooltipForSegment(item) && (
                            <SpanContainer data-tip={findTooltipForSegment(item)}>
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '-3px',
                                  marginTop: '0px',
                                  paddingTop: '0px',
                                }}
                              >
                                <AiOutlineInfoCircle
                                  color="rgba(26, 26, 26, 0.4)"
                                  fontSize="16px"
                                />
                              </div>
                            </SpanContainer>
                          )}
                        </TableSubTitle>
                      </td>
                      <td colSpan={7} />
                    </tr>
                  )}
                  {filteredTableData
                    .filter(c => c.segment === item)
                    .map(row => (
                      <tr key={row.company_name}>
                        <td style={{ position: 'sticky', left: 0, zIndex: 3 }}>
                          {useImageList.includes(row.company_name) ? (
                            <ImageContainer>
                              <a href={`https://${row.domain}`}>
                                <img
                                  src={require(`../../../content/img/research/logos/${row.company_name.replace(
                                    '%',
                                    '',
                                  )}.jpg`)}
                                  alt={row.company_name}
                                />
                              </a>
                            </ImageContainer>
                          ) : (
                            <a
                              style={{ color: theme.BLACK_COLOR }}
                              href={`https://${row.domain}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {row.company_name}
                              {/* <img
                          src={require(`../../../content/img/companies/${row.company_name}.png`)}
                          alt={row.company_name}
                        /> */}
                            </a>
                          )}
                        </td>
                        <td id="secondColumn">{row.segment}</td>
                        <td>
                          <WrappedText showMore={showMore} id="Companies Founders">
                            {row.founders.split(', ').map((item: any) => (
                              <FoundersBlock key={`${row.founders}-${item}`}>
                                {linkedInData.filter((i: any) => i.founder === item) ? (
                                  <TdAnchor
                                    href={linkedInData
                                      .filter((i: any) => i.founder === item)
                                      .map(data => data.linkedin)}
                                  >
                                    {item}
                                  </TdAnchor>
                                ) : (
                                  <TdText>{item}</TdText>
                                )}
                                {item ===
                                  row.founders.split(', ')[row.founders.split(', ').length - 1] ||
                                Boolean(!row.founders)
                                  ? ''
                                  : ',  '}
                              </FoundersBlock>
                            ))}
                          </WrappedText>
                          {Boolean(row.founders) && row.founders.length > 13 && (
                            <MoreButton showMore={showMore} id="Companies Founders" />
                          )}
                        </td>
                        <td>{row.stage}</td>
                        <td>{row.founded}</td>
                        <td>{row.hq_location}</td>
                        <td>{numberToCurrencyMorB(row.amt_raised)}</td>
                        <td>{Boolean(row.employees) && Number(row.employees).toLocaleString()}</td>
                        <td>
                          <WrappedText showMore={showMore} id="Companies Key Investors">
                            {row.key_investors}
                          </WrappedText>
                          {Boolean(row.key_investors) && row.key_investors.length > 12 && (
                            <MoreButton showMore={showMore} id="Companies Key Investors" />
                          )}
                        </td>
                        <td>
                          <WrappedText showMore={showMore} id="Companies Description" maxW="250px">
                            {row.description}
                          </WrappedText>
                          {Boolean(row.description) && row.description.length > 35 && (
                            <MoreButton showMore={showMore} id="Companies Description" />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ position: 'sticky', left: '0' }}>
                  <TableSubTitle>No result</TableSubTitle>
                </td>
                <td colSpan={7} />
              </tr>
            )}
          </table>
        </ResearchCompaniesDiv>
      </ResearchInvestorsFullDiv>

      <FlexTablesContainer>
        <FlexTableDiv>
          <ResearchInvestorsHalfDiv>
            <ResearchInvestorsTitle>
              Most Active Investors{' '}
              <SpanContainer
                style={{ marginTop: '4px' }}
                data-tip="Represents investors identified by Base10 who have made numerous and/or notable investments in the trend"
              >
                <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />
              </SpanContainer>
            </ResearchInvestorsTitle>
            <ResearchInvestorsDiv>
              <table>
                <thead>
                  <tr>
                    <th>Firm or Investor</th>
                    <th>Investments</th>
                    <th>Stage</th>
                    {/* <th>Key People</th> */}
                  </tr>
                </thead>
                <tbody>
                  {mostActiveInvestorsData.map((item: any) => (
                    <tr key={`T2-${item.firm_or_investor}`}>
                      <td style={{ paddingLeft: '0px' }}>{item.firm_or_investor}</td>
                      <td>
                        <FullWidthItem>
                          <WrappedText showMore={showMore} id="Most Active Investors Investments">
                            {item.investments}
                          </WrappedText>
                          {Boolean(item.investments) && item.investments.length > 14 && (
                            <MoreButton
                              showMore={showMore}
                              id="Most Active Investors Investments"
                              column="investments"
                              table="Most-Active-Investors"
                            />
                          )}
                        </FullWidthItem>
                      </td>
                      <td>{item.check_size_range}</td>
                      {/* <td>
                        <FullWidthItem>
                          <WrappedText showMore={showMore} id="Most Active Investors Key People">
                            {item.key_people}
                          </WrappedText>
                          {Boolean(item.key_people) && item.key_people.length > 14 && (
                            <MoreButton
                              showMore={showMore}
                              id="Most Active Investors Key People"
                              column="key_people"
                              table="Most-Active-Investors"
                            />
                          )}
                        </FullWidthItem>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ResearchInvestorsDiv>
          </ResearchInvestorsHalfDiv>
          <ResearchInvestorsHalfDiv>
            <ResearchInvestorsTitle>
              Investors in Top Companies{' '}
              <SpanContainer
                data-tip="Represents investors identified by Base10 who have made large investments into the top companies (by growth and/or size) in the trend"
                style={{ marginTop: '4px' }}
              >
                <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />
              </SpanContainer>
            </ResearchInvestorsTitle>
            <ResearchInvestorsDiv>
              <table>
                <thead>
                  <tr>
                    <th>Firm or Investor</th>
                    <th>Investments</th>
                    <th>Stage</th>
                    {/* <th>Key People</th> */}
                  </tr>
                </thead>
                <tbody>
                  {investorsInTopCompaniesData.map((item: any) => (
                    <tr key={`T3-${item.firm_or_investor}`}>
                      <td style={{ paddingLeft: '0px' }}>{item.firm_or_investor}</td>
                      <td>
                        <WrappedText
                          showMore={showMore}
                          id="Investors In Top Companies Investments"
                          style={{ minWidth: '100px' }}
                        >
                          {item.investment}
                        </WrappedText>
                        {Boolean(item.investment) && item.investment.length > 14 && (
                          <MoreButton
                            showMore={showMore}
                            id="Investors In Top Companies Investments"
                            column="investments"
                            table="Top-Companies-Investors"
                          />
                        )}
                      </td>
                      <td>{item.check_size_range}</td>

                      {/* <td>
                        <FullWidthItem>
                          <WrappedText showMore={showMore} id="Most Active Investors Key People">
                            {item.key_people}
                          </WrappedText>
                          {Boolean(item.key_people) && item.key_people.length > 14 && (
                            <MoreButton
                              showMore={showMore}
                              id="Investors In Top Companies Key People"
                              column="key_people"
                              table="Top-Companies-Investors"
                            />
                          )}
                        </FullWidthItem>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ResearchInvestorsDiv>
          </ResearchInvestorsHalfDiv>
        </FlexTableDiv>
      </FlexTablesContainer>
      <DisclaimerDiv>
        <DisclaimerText>
          Disclaimers: This is not meant to be comprehensive, just our view of how this trend is
          evolving and the interesting approaches to solving these problems in the trend. If you
          would like to be included and we missed you, please shoot us a note at{' '}
          <AnimatedLinkAnchor
            to="mailto:companies@base10.vc"
            color={theme.PRIMARY_COLOR}
            fontSize="14px"
            paddingBottom="1px"
          >
            companies@base10.vc
          </AnimatedLinkAnchor>
          .
        </DisclaimerText>
      </DisclaimerDiv>
    </ShopifyInsightsContainer>
  );
};

const DisclaimerDiv = styled.div`
  margin-top: 23px;
  background-color: ${theme.WHITE_COLOR};
  padding: 13px;
`;

const DisclaimerText = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: rgba(26, 26, 26, 0.4);
  max-width: 1000px;
  margin: auto auto;
`;

const NavSelectLabel = styled(AppText)`
  font-size: 11px;
  color: ${theme.BLACK_COLOR};
  line-height: 20px;
`;

const NavSelect = styled.select`
  padding: 5px;
  margin-bottom: 25px;
  position: relative;
  font-size: 12px;
  max-width: 80vw;
`;

const EmailSubmitButton = styled.button`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.BLACK_COLOR};
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 4px;
  min-width: 80px;
  height: 26px;
`;

const EmailTextInput = styled.input`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  line-height: 14px;
  color: rgba(26, 26, 26, 0.8);
  border: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 15px 5px 20px;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EmailForm = styled.form`
  display: grid;
  grid-template-columns: 150px 200px;
  max-width: fit-content;
  margin-bottom: 28px;
  background-color: rgba(26, 26, 26, 0.2);
  padding: 5px;
  border-radius: 4px;
  @media ${mobileSize} {
    max-width: 100%;
    margin-top: 10px;
    grid-template-columns: 100px 1fr;
  }
`;

const TdAnchor = styled.a`
  display: inline;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  border-bottom: 1px solid ${theme.PRIMARY_COLOR};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TdText = styled(AppText)`
  display: inline;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FlexTablesContainer = styled.div`
  max-width: 1400px;
`;

interface WrappedTextProps {
  showMore: string[];
  maxW?: string;
  id: string;
}

const WrappedText = styled.div<WrappedTextProps>`
  border: 0;
  font-family: ${theme.PRIMARY_FONT};
  display: inline-block;
  font-size: 14px;
  line-height: 1.57;
  height: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  min-width: ${props => props.maxW ?? '50px'};
  max-width: ${props => (props.showMore.includes(props.id) ? '1000px' : '100px')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
`;

const ResearchInvestorsFullDiv = styled.div`
  max-width: 1400px;
`;

const FlexTableDiv = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const ResearchInvestorsHalfDiv = styled.div`
  max-width: 650px;
  width: 45vw;
  justify-self: center;
  @media ${mobileSize} {
    width: 90vw;
  }
`;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

const MoreButtonStyle = styled.button`
  padding: 0;
  margin: 0;
  padding-left: 5px;
  border: none;
  background: transparent;
  color: ${theme.PRIMARY_COLOR};
`;

const FullWidthItem = styled.div`
  width: max-content;
  display: block;
  white-space: nowrap;
`;

const FoundersBlock = styled.div`
  display: inline;
  white-space: nowrap;
`;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const FilterByLabel = styled.div`
  opacity: 0.6;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  margin-right: 8px;
  margin-left: 14px;
`;

const FitlerSelect = styled.select`
  background: transparent;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  border-bottom: 1px solid ${theme.PRIMARY_COLOR};
  margin: 0px;
  margin-right: 8px;
  max-width: 150px;
  text-overflow: ellipsis;
  option {
    font-family: ${theme.PRIMARY_FONT};
  }
  @media ${mobileSize} {
    margin: 5px;
  }
`;

const FiltersList = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 8px;
  flex-wrap: wrap;
`;

const TableSubTitle = styled.div`
  font-family: ${theme.SECONDARY_FONT};
  margin: 27px 0px 8px 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.33px;
  color: ${theme.BLACK_COLOR};
  text-transform: uppercase;
  acronym {
    background: black;
    color: white;
  }
`;

const ResearchCompaniesDiv = styled.div`
  max-width: 95vw;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  height: 500px;
  th {
    font-family: ${theme.SECONDARY_FONT};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.67px;
    color: rgba(26, 26, 26, 0.6);
    text-align: left;
    /* border-bottom: 1px solid ${theme.BLACK_COLOR}; */
    /* border-right: 8px solid ${theme.OFF_WHITE_BACKGROUND}; */
    padding: 8px 12px 8px 0px;
    vertical-align: bottom;
    background-clip: padding-box;
    border-collapse: collapse;
    position: sticky;
    top: 0;
    background-color: ${theme.OFF_WHITE_BACKGROUND};
    z-index: 3;
  }
  th:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    height: 1px;
    width: calc(100% - 8px);
    margin-top: 0px;
    background-color: ${theme.BLACK_COLOR};
  }
  table {
    width: 100%;
    position: relative;
    border-collapse: separate;
  }
  td {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    border-bottom: 1px solid rgb(203, 204, 205);
    padding-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-clip: padding-box;
    background-color: ${theme.OFF_WHITE_BACKGROUND};
    @media ${mobileSize} {
      line-height: 1.1;
    }
  }
  img {
    max-height: 18px;
    padding: 0;
  }
  #secondColumn {
    position: sticky;
    left: 100px;
    z-index: 3; 
    padding-right: 12px;
  }
  #secondColHead {
    position: sticky; 
    left: 100px; 
    z-index: 4;
  }
  @media ${laptopSize} {
    max-width: 90vw;
    overflow: auto;
    #secondColumn {
      position: relative;
      left: auto;
      z-index: 1; 
      padding-right: 12px;
    }
    #secondColHead {
      position: sticky;
      top: 0;
      left: auto; 
      z-index: 3;
    }
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
  }
`;

const ResearchInvestorsDiv = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  th {
    opacity: 0.6;
    font-family: ${theme.SECONDARY_FONT};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.67px;
    color: ${theme.BLACK_COLOR};
    text-align: left;
    border-bottom: 1px solid ${theme.BLACK_COLOR};
    border-right: 8px solid ${theme.OFF_WHITE_BACKGROUND};
    border-left: 0px;
    border-collapse: collapse;
    padding: 8px 4px 8px 0px;
    vertical-align: bottom;
  }
  table {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
  }
  td {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    border-bottom: 1px solid rgba(26, 26, 26, 0.2);
    border-collapse: collapse;
    padding-left: 4px;
    padding-right: 12px;
    @media ${mobileSize} {
      line-height: 1.1;
    }
  }
  img {
    max-width: 50px;
    max-height: 24px;
    margin: 5px;
  }
  @media ${laptopSize} {
    max-width: 90vw;
    overflow: auto;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
  }
`;

const ResearchInvestorsTitle = styled.div`
  margin-top: 31px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-top: 10px;
  }
`;

const KeyInsightsListItems = styled.div`
  padding-bottom: 20px;
  ol {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    list-style: none;
    counter-reset: li;
    margin: 0;
  }
  ol li::before {
    content: counter(li);
    color: ${theme.WHITE_COLOR};
    font-family: ${theme.PRIMARY_FONT};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    text-align: left;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px;
    margin-top: 2px;
    margin-left: -31px;
  }
  ol li:nth-child(3n + 1):before {
    background-color: ${listColors[0]};
  }
  ol li:nth-child(3n + 2):before {
    background-color: ${listColors[1]};
  }
  ol li:nth-child(3n + 3):before {
    background-color: ${listColors[2]};
  }
  ol li {
    counter-increment: li;
    padding: 0;
    margin: 0;
    margin-left: 15px;
    line-height: 20px;
    margin-bottom: 8px;
    /* padding-left: 5px; */
    list-style-position: outside;
    vertical-align: bottom;
  }
`;

const KeyInsightsListTitle = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 8px;
`;

interface SummaryTitleProps {
  bold?: boolean;
}

const SummaryTitleText = styled(AppText)<SummaryTitleProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${props => (props.bold ? '16px' : '13px')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${theme.BLACK_COLOR};
  display: inline;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  opacity: ${props => (props.bold ? '1' : '0.6')};
`;

const SummaryTitle = styled.div`
  padding-bottom: 10px;
`;

const ShopifyInsightsContainer = styled.div`
  padding: 130px 25px 100px 25px;
  max-width: 1400px;
  margin: auto auto;
  background-color: ${theme.OFF_WHITE_BACKGROUND};
  font-family: ${theme.PRIMARY_FONT};
  @media ${mobileSize} {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const ImageContainer = styled.div`
  height: 18px;
  width: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-top: 3px;
`;

export { Research4Segment };
